import request from '@/utils/request'


export function getNews(params) {
  return request({
    url: '/news/page',
    // url: '/news',
    method: 'get',
    params:params
  })
}


export function getNewsDetails(id) {
  return request({
    url: '/news/newsDetails/'+ id,
    // url: '/newsDetails/'+ id,
    method: 'get',
  })
}

export function addUser(data) {
  return request({
    url: '/application/add',
    method: 'post',
    data:data
  })
}

export function getJobList(params) {
	return request({
		url: '/job/page',
		method: 'get',
    params:params
	})
}

export function getJobDetails(id) {
  return request({
    url: '/job/info/'+ id,
    method: 'get',
  })
}

export function submitJob(data) {
	return request({
		url: '/job/deliver',
		method: 'post',
		data,
	})
}

export function uploadFile(data){
  return request({
		url: '/common/uploads',
		method: 'post',
		data,
	})
}
export function getHome(){
  return request({
		url: '/home',
		method: 'get',
	})
}

